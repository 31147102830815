var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"notifyManual"},[_c('b-form',{staticClass:"mt-2"},[_c('h4',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("NotificacionesManuales")))]),_c('b-row',{staticClass:"pl-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('TipoNotificacion')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('TipoNotificacion'),"label-for":"task-event"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.optionsNotify,"name":"radio-validation"},model:{value:(_vm.notify),callback:function ($$v) {_vm.notify=$$v},expression:"notify"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.notify == 'email')?_c('b-col',{staticClass:"pt-1",attrs:{"sm":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('MensajeAEnviar'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('MensajeAEnviar'),"label-for":"task-description"}},[_c('quill-editor',{model:{value:(_vm.textmessage),callback:function ($$v) {_vm.textmessage=$$v},expression:"textmessage"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4143829065)})],1):_vm._e(),(_vm.notify == 'sms')?_c('b-col',{staticClass:"pt-1",attrs:{"sm":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('MensajeAEnviar'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('MensajeAEnviar'),"label-for":"task-event"}},[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":_vm.$t('MensajeAEnviar'),"rows":"3","max-rows":"6","maxlength":"70"},model:{value:(_vm.textmessage),callback:function ($$v) {_vm.textmessage=$$v},expression:"textmessage"}}),_c('p',[_vm._v(_vm._s(_vm.textmessage.length)+"/70")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,8379076)})],1):_vm._e(),(_vm.notify && _vm.currentRole == 'admin_empresa')?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Cliente')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Cliente'),"label-for":"account-clients"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectClients,"placeholder":_vm.$t('TodosClientes')},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2527625628)})],1):_vm._e(),(_vm.notify)?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Roles')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Roles'),"label-for":"task-event"}},[_c('b-form-checkbox-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.selectRoles,"name":"radio-validation","value-field":"id","text-field":"display_name"},model:{value:(_vm.rol),callback:function ($$v) {_vm.rol=$$v},expression:"rol"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3272192619)})],1):_vm._e(),(_vm.notify && _vm.currentRole != 'admin_empresa')?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Departamentos')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Departamentos'),"label-for":"task-event"}},[_c('b-form-checkbox-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.selectDepartments[0].data,"name":"radio-validation","value-field":"id","text-field":"name"},model:{value:(_vm.dept),callback:function ($$v) {_vm.dept=$$v},expression:"dept"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1813394588)})],1):_vm._e(),(_vm.notify)?_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t("Enviar"))+" ")])],1):_vm._e()],1)],1)],1)],1),(_vm.currentRole == 'admin_cliente')?_c('b-card',[_c('validation-observer',{ref:"notifyAuto"},[_c('b-form',{staticClass:"mt-2"},[_c('h4',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("NotificacionesAutomaticas")))]),_c('b-row',{staticClass:"pl-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('TipoNotificacion'),"label-for":"task-event"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":"checkbox-notifyAutoCreate","name":"checkbox-notifyAutoCreate","value":true,"unchecked-value":false},model:{value:(_vm.notifyAutoCreate),callback:function ($$v) {_vm.notifyAutoCreate=$$v},expression:"notifyAutoCreate"}},[_vm._v(" "+_vm._s(_vm.$t('Notificar cuando un evento se crea'))+" ")])],1)],1),(_vm.notifyAutoCreate)?_c('b-col',{staticClass:"pl-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Roles')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Roles'),"label-for":"task-event"}},[_c('b-form-checkbox-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.selectRoles,"name":"radio-validation","value-field":"id","text-field":"display_name"},model:{value:(_vm.rolAutoCreate),callback:function ($$v) {_vm.rolAutoCreate=$$v},expression:"rolAutoCreate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3476432032)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"task-event"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-notifyAutoChange","name":"checkbox-notifyAutoChange","value":true,"unchecked-value":false},model:{value:(_vm.notifyAutoChange),callback:function ($$v) {_vm.notifyAutoChange=$$v},expression:"notifyAutoChange"}},[_vm._v(" "+_vm._s(_vm.$t('Notificar cuando un evento cambia de estado'))+" ")])],1)],1),(_vm.notifyAutoChange)?_c('b-col',{staticClass:"pl-3",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Roles')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Roles'),"label-for":"task-event"}},[_c('b-form-checkbox-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.selectRoles,"name":"radio-validation","value-field":"id","text-field":"display_name"},model:{value:(_vm.rolAutoChange),callback:function ($$v) {_vm.rolAutoChange=$$v},expression:"rolAutoChange"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3142490498)})],1):_vm._e(),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.handleSubmitAuto}},[_vm._v(" "+_vm._s(_vm.$t("Guardar"))+" ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }